<template>
	<div class="container-fluid">
		<div style="z-index: 999999; position: relative">
			<headers @scroll="scroll" />
		</div>
		<router-view :key="key"></router-view>
		<footers id="footer" />
	</div>
</template>

<script>
	export default {
		provide() {
			return {
				scroll: this.scroll
			}
		},
		computed: {
			key() {
				return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
			}
		},
		methods: {

			scroll() {
				document.getElementById('footer').scrollIntoView()
			},

		}
	}
</script>

<style>
</style>
